<template>
  <n-card class="menu">
    <div class="container">
      <!-- <img class="logo" src="https://firebasestorage.googleapis.com/v0/b/starting-real-estate.appspot.com/o/assets%2Flogos%2FStarting%20Real%20Estate%20-%20House%20Only-1.png?alt=media&token=be9cffbf-c363-4e56-bf64-b0f60dc60332" alt="logo" @click="goHome"> -->
      <img class="logo" src="https://firebasestorage.googleapis.com/v0/b/horizon-capital-group.appspot.com/o/website%2FHorizon.png?alt=media&token=59a02c47-feb0-4c3f-b539-3310c8bbfa3c" alt="logo" @click="goHome">
      <n-menu :key="navBarKey" class="inMenu"  mode="horizontal" :options="menuOptions" @update:value="handler"/>
      <n-menu :key="navBarKey" class="inMenuMobile"  mode="horizontal" :options="menuOptionsMobile" @update:value="handler"/>
    </div>
  </n-card>
</template>

<script setup>
import {ref} from 'vue'
// import { useAuth0 } from '@auth0/auth0-vue';
import { NMenu, NCard } from 'naive-ui'
import { useRouter } from "vue-router";
// import {
//   BookOutline as BookIcon,
//   PersonOutline as PersonIcon2,
//   WineOutline as WineIcon,
//   HomeOutline as HomeIcon,
//   PersonCircleOutline as PersonIcon,
//   SettingsOutline as SettingsIcon
// } from '@vicons/ionicons5'

const router = useRouter();

// const {loginWithRedirect, handleRedirectCallback, isAuthenticated, logout} = useAuth0();


const navBarKey = ref(0);

  const menuOptions = [
  {
    label: () => "About",
    key: "about",
  },
  {
    label: "Portfolio",
    key: "portfolio",
  },
    {
    label: "Team",
    key: "team",
  },
  {
    label: "Contact",
    key: "contact",
  },
  ]

    const menuOptionsMobile = [
  {
    label: "Portfolio",
    key: "portfolio",
  },
    {
    label: "Team",
    key: "team",
  },
  {
    label: "Contact",
    key: "contact",
  },
  ]

  const goHome = () => {
    router.push("/")
  }

    function handler (key, item) {
    console.log("handlerLogin "+ key +" " + JSON.stringify(item))
    if (key=="portfolio") {
      scrollToPortfolio()
    }
    if (key=="contact") {
      scrollToEmail()
    }
    if (key=="about") {
      scrollToAbout()
    }
    if (key=="team") {
      scrollToTeam()
    }
  }

    const scrollToEmail = () => {
      try {
        document.getElementById('email-section').scrollIntoView()

      } catch (error) {
        goHome()
      }
    }
    const scrollToPortfolio = () => {
      try {
        document.getElementById('portfolio-section').scrollIntoView();
      } catch (error) {
        goHome()
      }
    }
    const scrollToAbout = () => {
      try {
        document.getElementById('about-section').scrollIntoView();
      } catch (error) {
        goHome()
      }
    }
    const scrollToTeam = () => {
      try {
        document.getElementById('team-section').scrollIntoView();

      } catch (error) {
        goHome()
      }
    }

</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,700&family=Outfit&family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,600&display=swap');

@media only screen and (max-width: 1000px) {
  .inMenu {
    visibility: hidden;
    display: none;
  }

  /* .inMenuTeam {
    visibility: hidden;
    display: none;
  } */
  
  .inMenuMobile { 
    /* font-weight: 400; */
    font-size: 14px;
    font-family: 'Cabin', sans-serif;
    color: black;
  }


  nav {
  padding: 15px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #4276b9;
}

.menu {
  background-color: white;
  /* font-family: "Merriweather"; */
}


/* Change the NavBar menu color and the Text color here */
.n-menu.n-menu--horizontal .n-menu-item-content .n-menu-item-content-header a {
  color: black;
  font-weight: 1200;
  /* font-size: 12px; */
  border-color: transparent;
}

.n-card {
  color: rgb(2, 65, 0);
}

.inMenu {
  color: #0db7ed;
}

html {
    background: rgb(255, 255, 255);
}

.menu .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5% 0 5%;
}

.menu .container img {
  /* width:5%; */
  transition: 0.5s;
  rotate: 180;
}

.menu .container img:hover{
    background:rgb(155, 255, 151);
}

}

@media only screen and (min-width: 1000px) {
  .inMenu { 
    /* font-weight: 400; */
    font-size: 18px;
    font-family: 'Cabin', sans-serif;
    color: black;
  }

  .inMenuMobile {
    display: none;
   }
  nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #4276b9;
}

.menu {
  background-color: white;
}

/* Change the NavBar menu color and the Text color here */
.n-menu.n-menu--horizontal .n-menu-item-content .n-menu-item-content-header a {
  color: rgb(0, 0, 0);
  font-weight: 1200;
  font-size: 18px;
  border-color: transparent;
}

.n-card {
  color: rgb(2, 65, 0);
}

.inMenu {
  color: #0db7ed;
}

html {
    background: rgb(255, 255, 255);
}

.menu .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5% 0 5%;
}

.menu .container img {
  width:5%;
  transition: 0.5s;
  rotate: 180;
}

.menu .container img:hover{
    background:#A9F285;
}

.two-col-grid {
  display: grid;
  justify-content: start;
  align-items: stretch;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 500px;
}

.two-col-grid .img-content-right-wrapper > img {
  max-height: 100%;
}

.two-col-grid .content-left-wrapper {
  max-height: 100%;
}

.two-col-grid .tagline {
  width: auto;
  height: auto;
  font-size: 60px;
  font-weight: 700;
  color:#000000;
  padding: 0 0 0 0;
  /* max-height: 80%; */
}

.content-left-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 15%;
  justify-content: center;
  text-align: left;
  /* height: auto; */
}


.two-col-grid .subtext {
  font-size: 25px;
  font-weight: 400;
}

.company-name {
  font-size: 25px;
  font-weight: 700;
  font-family: "Merriweather";
  color: black;
  text-align: left;
}
}

</style>
