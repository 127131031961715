import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

var firebaseConfig = {
    apiKey: "AIzaSyA6WXPWZjUzda44J0rOWi7yKuWen69_VqI",
    authDomain: "starting-real-estate.firebaseapp.com",
    projectId: "starting-real-estate",
    storageBucket: "starting-real-estate.appspot.com",
    messagingSenderId: "1036818359747",
    appId: "1:1036818359747:web:8fc04abe3ffb4dcefb0976",
    measurementId: "G-XVPN8CYH08"
  };
const firebaseApp = firebase.initializeApp(firebaseConfig);

export default firebaseApp.firestore();

const app = createApp(App)

app.use(router).mount('#app')
